import MainImg from "../imgs/main-trees.webp";
import Button from "../components/Button";

const LandingPage = () => {
  return (
    <div className="desktop:flex  items-center">
      <div className="desktop:min-w-96 desktop:w-5/12 space-y-8">
        <h1
          className="font-roboto font-medium text-7xl 
        half:text-6xl mobile:text-4xl w-auto min-w-0"
        >
          <span className="text-orange">Suprematism is a</span> modernist
          movement
        </h1>
        <p className="font-roboto text-lg mobile:text-justify">
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere dolor
          sunt iure, similique aliquam perspiciatis voluptatum cupiditate
          dolorum veniam voluptas qui inventore officiis quo, minima alias
          eligendi! Adipisci, dolorum earum? Lorem ipsum, dolor sit amet
          consectetur adipisicing elit. Facere dolor sunt iure, similique
          aliquam perspiciatis.
        </p>
        <Button color="black">EXPLORE MORE</Button>
      </div>
      <div className="ml-4 -mt-24 flex-grow -z-1">
        <img
          className="w-full mobile:hidden"
          src={MainImg}
          alt="fancy vase with little trees inside"
        ></img>
      </div>
    </div>
  );
};

export default LandingPage;
