type ButtonColor = "orange" | "black";

const Button = (props: {
  onClick?: any;
  children: string;
  color?: ButtonColor;
  className?: string;
}) => {
  const color: ButtonColor = props.color || "orange"; // Default is orange
  return (
    // <button
    //   className={`p-6 font-roboto text-white
    //               bg-${color} ${className}`}
    //   onClick={() => onClick()}
    // >
    //   {text}
    // </button>
    <button
      className={`px-6 py-5 font-roboto text-white text-lg font-medium
                  bg-${color} hover:bg-${color}-light focus:bg-${color}-dark
                  transition
                  ${props.className || ""}`}
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
    >
      {props.children}
    </button>
  );
};

export default Button;
