const Logo = () => {
  return (
    <div className="flex items-center hover:cursor-pointer">
      <div
        className="w-14 h-14 bg-white
                   border-8 border-orange
                   rounded-l-full rounded-br-full"
      ></div>
      <p className="ml-2 font-roboto font-medium text-4xl">Pello</p>
    </div>
  );
};

export default Logo;
