import NavBar from "./components/NavBar";
import LandingPage from "./pages/LandingPage";

const App = () => {
  return (
    <>
      <NavBar />
      <div className="px-24">
        <LandingPage />
      </div>
    </>
  );
};

export default App;
