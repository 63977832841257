import Logo from "../components/Logo";
import Button from "./Button";

const Link = (props: { children: any; to?: string }) => {
  return (
    <p
      className="mx-6 text-roboto font-medium text-lg
    hover:cursor-pointer hover:underline"
    >
      {props.children}
    </p>
  );
};

const NavBar = () => {
  return (
    <div
      className="py-6 px-20 mt-4
            flex justify-between items-center"
    >
      <Logo />

      <div className="flex">
        <Link>HOME</Link>
        <Link>ABOUT US</Link>
        <Link>OUR WORK</Link>
        <Link>CASE STUDIES</Link>
      </div>

      <Button onClick={() => console.log("click")} color="orange">
        EXPLORE MORE
      </Button>
      {/* <button
        className={`p-6 font-roboto text-white
                  bg-orange`}
        onClick={() => alert("hi")}
      >
        hi
      </button> */}
    </div>
  );
};

export default NavBar;
